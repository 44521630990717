#landing-page {
  height: 100%;
}

.img-container > img {
  margin-top: -40px;
  width: 90%;
  height: inherit;
  object-fit: cover;
}
#landing-page,
.header-2,
.footer,
.expertise-summary {
  background: rgb(0, 11, 22);
  background: linear-gradient(
    135deg,
    rgba(0, 11, 22, 1) 30%,
    rgba(0, 0, 39, 1) 100%
  );

  /* background-color: #051c2c;
  background: linear-gradient(135deg, #051c2c 60%, #03339c 100%);
  color: #fff;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2); */
}

.expertise-summary-2 {
  background-color: #000000;
  /* background: linear-gradient(135deg, #051c2c 60%, #051c2c 100%);
  color: #fff;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2); */
}

.nav-transparent .navbar-nav .nav-link {
  color: #000000 !important;
}

.nav-transparent .navbar-nav .nav-link:hover {
  color: #000000 !important;
}

.navbar .navbar-nav .nav-link.active {
  color: #ffffff !important;
  border-bottom: 3px solid rgba(240, 50, 60, 0.9) !important;
}
.nav-link {
  border: transparent;
  margin: 5px;
}
.nav-link:hover {
  border-bottom: 3px solid rgba(240, 50, 60, 0.9) !important;
  /* transition: 0.3s; */
}

.portfolio-action-btn {
  color: #000027;
  border-color: #000027;
}

.portfolio-action-btn:hover,
.contact-action-btn,
.contact-action-btn:hover {
  color: #ffffff;
  background-color: #000027;
  border-color: #000027;
}

/*
::-moz-selection {
  /* Code for Firefox */
/* color: rgba(235, 99, 99); */
/* background: rgba(235, 235, 235, 0.6); */
/* } */

/* 
::selection {
  color: rgba(235, 95, 99);
  background: rgba(235, 235, 235, 0.6);
} */

.btn-soft-danger:hover {
  background-color: rgba(240, 50, 60, 0.15) !important;
  box-shadow: 0 1px 1px rgba(240, 50, 60, 0.1),
    0 2px 4px 1px rgba(240, 50, 60, 0.1) !important;
  border-color: rgba(240, 50, 60, 0.1);
  color: #f0323c;
}
.btn-soft-danger {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #f0323c;
  box-shadow: transparent !important;
}

.footer-bottom .icon:hover {
  color: #f0323c;
}

.expertise-item:hover {
  border-right: 3px rgba(240, 50, 60, 0.15) solid;
  padding-left: 5px;
  cursor: pointer;
}

.grey-background {
  background-color: rgba(247, 247, 247);
}

/* mobile optimization */
@media only screen and (max-width: 600px) {
  .hero-4 .hero-title {
    padding-top: 1em;
  }
  .features-3 {
    padding-top: 9em;
  }
  .hero-7 .hero-title,
  .hero-9 .hero-title,
  .team {
    padding-top: 4em;
  }
  .navbar-nav {
    background: rgb(0, 11, 22);
    background: linear-gradient(
      135deg,
      rgba(0, 11, 22, 1) 30%,
      rgba(0, 0, 39, 1) 100%
    );
  }
  .nav-transparent .navbar-nav .nav-link {
    color: #ffffff !important;
  }
  #contact-hero .shape.bottom > svg {
    width: auto;
  }
  .finacc-services .service-col {
    height: auto !important;
  }
  .finacc-services .service-col div {
    margin: 1.4rem !important;
  }

  .finacc-services .service-col h1 {
    font-size: 2rem !important;
    letter-spacing: normal !important;
  }
  .service-col-image {
    display: none !important;
  }
}

a:hover {
  color: rgba(240, 50, 60, 0.9);
}
